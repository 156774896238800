import img1 from '../images/post/imgpost11.jpg'
import img2 from '../images/post/imgpost13.jpg'
import img3 from '../images/post/imgpost12.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 2,
        img : img2,
        category: 'nftartwork',
        title: 'Max Live X Tacnocde Matter NFT Music ',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 3,
        img : img3,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 4,
        img : img1,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 5,
        img : img2,
        category: 'nftartwork',
        title: 'Max Live X Tacnocde Matter NFT Music ',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
    {
        id: 6,
        img : img3,
        category: 'digitalart',
        title: 'NFT Guide: Why They Matter For Music?',
        time: '20 Jun 2022',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor.',
    },
]

export default dataBlog;